<template>
    <div>
        <ts-page-title
            :title="$t('pickupRequest.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('pickupRequest.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-loading-banner :loading="warn_request_confirm">
                <ts-panel-wrapper>
                    <div class="tw-flex tw-justify-between">
                        <div class="tw-flex tw-space-x-3">
                            <a-button
                                type="primary"
                                @click="addNew"
                                v-if="$can('create-pickup-requests')"
                            >
                                {{ $t("addNew") }}
                            </a-button>
                            <a-date-picker
                                v-model="dateRequest"
                                format="DD-MM-YYYY"
                                valueFormat="DD-MM-YYYY"
                                :allowClear="false"
                                @change="fetchData()"
                            />
                            <a-select
                                v-model="status"
                                style="width: 200px"
                                placeholder="All"
                                :allowClear="true"
                                :options="[
                                    { value: 1, label: 'Requesting' },
                                    { value: 2, label: 'Confirmed' },
                                    { value: 3, label: 'Completed' },
                                    { value: 4, label: 'Cancelled' }
                                ]"
                                @change="fetchData()"
                            >
                            </a-select>
                            <a-select
                                v-model="types"
                                style="width: 200px"
                                placeholder="All"
                                :allowClear="true"
                                :options="[
                                    { value: 1, label: 'បញ្ញើរហ័ស	' },
                                    { value: 2, label: 'បញ្ញើធម្មតា	' }
                                ]"
                                @change="fetchData()"
                            >
                            </a-select>
                            <a-select
                                v-if="$whois.admin()"
                                v-model="agency_id"
                                style="width: 220px"
                                :options="agencies"
                                showSearch
                                mode="multiple"
                                placeholder="All Agency"
                                :max-tag-count="1"
                                :max-tag-text-length="7"
                                :filterOption="
                                    (input, option) =>
                                        option.componentOptions.children[0].text
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                "
                                @change="fetchData()"
                            >
                            </a-select>
                        </div>
                        <a-tooltip :title="$t('pickupRequest.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('pickupRequest.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </ts-panel-wrapper>
                <div class="tw-overflow-x-scroll">
                    <ts-table
                        :columns="columns"
                        :records="resources"
                        :loading="loading"
                    >
                        <template v-slot="{ record, index }">
                            <td class="tw-whitespace-nowrap">
                                {{ record.request_time }}
                            </td>
                            <td class="tw-whitespace-nowrap">{{ record.shop_name }}</td>
                            <td>{{ record.phone_number }}</td>
                            <td class="tw-whitespace-nowrap">
                                {{ record.destination }}
                            </td>
                            <td>{{ record.package_type_kh }}</td>
                            <td>
                                <div
                                    class="tw-flex tw-space-x-2"
                                    v-if="index !== row_edit"
                                >
                                    <span>
                                        {{ record.service_type_name_kh }}</span
                                    >
                                    <a
                                        href="#"
                                        @click="
                                            onRowClickEditServiceType(
                                                record,
                                                index
                                            )
                                        "
                                        v-if="record.pickup_status_id != 3"
                                        ><i class="far fa-edit"></i
                                    ></a>
                                </div>
                                <div
                                    v-if="index == row_edit"
                                    class="tw-flex tw-space-x-2 tw-items-center"
                                >
                                    <a-select
                                        v-model="row_edit_model.service_type_id"
                                        style="width: 100%; min-width: 150px;"
                                        :options="[
                                            { value: 1, label: 'បញ្ញើរហ័ស	' },
                                            { value: 2, label: 'បញ្ញើធម្មតា	' }
                                        ]"
                                    >
                                    </a-select>
                                    <a href="#" @click.prevent="onSbmitChange"
                                        ><i class="far fa-save tw-text-lg"></i
                                    ></a>
                                    <a
                                        href="#"
                                        @click.prevent="row_edit = undefined"
                                        ><i
                                            class="fas fa-times tw-text-red-500 tw-text-lg"
                                        ></i
                                    ></a>
                                </div>
                            </td>
                            <td>
                                {{
                                   record.driver_name
                                }}
                            </td>
                            <td class="tw-whitespace-nowrap">
                                <template>
                                    <p class="tw-mb-0">
                                        <span>{{
                                            record.phone1 +
                                                " | " +
                                                (record.phone2 || '')
                                        }}</span>
                                    </p>
                                </template>
                            </td>
                            <td>
                                {{ record.assign_driver }}
                            </td>
                            <td class="tw-text-center">
                                {{ record.booking_pickups_count }}
                            </td>
                            <td>{{ record.pickup_status }}</td>
                            <td>
                                {{
                                    record.cancel_reason
                                        ? record.cancel_reason
                                        : ""
                                }}
                            </td>
                            <td>
                                {{ record.created_by_name }}
                            </td>
                            <td
                                class="tw-text-left tw-space-x-2 tw-whitespace-nowrap"
                            >
                                <!-- assing driver -->
                                <a-tooltip placement="topLeft">
                                    <template #title>
                                        <span>Assign driver</span>
                                    </template>
                                    <a
                                        v-if="record.pickup_status_id == 1"
                                        href="#"
                                        class="text-primary mr-2"
                                        @click.prevent="
                                            showFormAssignDriver(record)
                                        "
                                    >
                                        <i class="fas fa-motorcycle"></i>
                                    </a>
                                </a-tooltip>
                                <!-- search near by driver -->
                                <a-tooltip placement="topLeft">
                                    <template #title>
                                        <span>Search near by driver</span>
                                    </template>
                                    <a
                                        v-if="record.pickup_status_id == 1"
                                        href="#"
                                        class="text-primary mr-2"
                                        @click.prevent="
                                            showFormAssignBookingRequestDriver(
                                                record
                                            )
                                        "
                                    >
                                        <i class="fa fa-map-marker"></i>
                                    </a>
                                </a-tooltip>
                                <!-- cancel request -->
                                <a-tooltip placement="topLeft">
                                    <template #title>
                                        <span>Cancel request</span>
                                    </template>
                                    <a
                                        v-if="record.pickup_status_id != 3"
                                        href="#"
                                        class="text-primary mr-2"
                                        @click.prevent="
                                            showCancelRequest(record)
                                        "
                                    >
                                        <i class="fas fa-ban"></i>
                                    </a>
                                </a-tooltip>
                                <a-tooltip placement="topLeft">
                                    <template #title>
                                        <span
                                            >Confirm request and redirect to
                                            booking!</span
                                        >
                                    </template>
                                    <a
                                        v-if="
                                            record.alert_to_driver &&
                                                record.pickup_status_id == 1
                                        "
                                        href="#"
                                        class="text-primary mr-2"
                                        @click.prevent="
                                            showConfirmRequest(record)
                                        "
                                    >
                                        <i class="fas fa-clipboard-check"></i>
                                    </a>
                                </a-tooltip>
                                <a-tooltip placement="topLeft">
                                    <template #title>
                                        <span>Create booking</span>
                                    </template>
                                    <a
                                        v-if="
                                            $can('create-booking-pickups') &&
                                                record.pickup_status_id == 2
                                        "
                                        href="#"
                                        v-tooltip="
                                            $t('pickupRequest.createBooking')
                                        "
                                        class="text-primary mr-2"
                                        @click.prevent="createBooking(record)"
                                    >
                                        <i class="fas fa-cart-plus"></i>
                                    </a>
                                </a-tooltip>
                            </td>
                        </template>
                    </ts-table>
                </div>
                <div class="d-flex justify-content-end tw-p-4">
                    <ts-pagination
                        v-model="pagination"
                        @navigate="fetchData"
                    ></ts-pagination>
                </div>
            </ts-loading-banner>
        </ts-panel>

        <a-modal
            v-model="show_assign_driver"
            :title="$t('pickupRequest.assignDriver')"
            @ok="assignDriver"
            :okText="$t('save')"
            :centered="true"
        >
            <ts-loading-banner :loading="fetch_driver">
                <div class="row">
                    <div class="col-md-12 tw-space-y-2">
                        <label class="required">{{
                            $t("pickupRequest.driver")
                        }}</label>
                        <a-select
                            v-model="model.driver_id"
                            :options="drivers"
                            showSearch
                            :filterOption="
                                (input, option) =>
                                    option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                            "
                            class="tw-w-full"
                        ></a-select>
                    </div>
                </div>
            </ts-loading-banner>
        </a-modal>
        <a-modal
            v-model="show_cancel_request"
            :title="$t('pickupRequest.cancelPickupRequest')"
            @ok="cancelRequest"
            :centered="true"
        >
            <ts-loading-banner :loading="cancel_request_loading">
                <div class="row">
                    <div class="col-md-12 tw-space-y-2">
                        <label class="required">{{
                            $t("pickupRequest.cancelReason")
                        }}</label>
                        <div class="tw-w-full tw-space-y-2">
                            <a-select
                                v-model="cancel_reason"
                                :options="cancelReason"
                                showSearch
                                :filterOption="
                                    (input, option) =>
                                        option.componentOptions.children[0].text
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                "
                                class="tw-w-full"
                            ></a-select>
                            <textarea
                                v-if="cancel_reason == 'ផ្សេងៗ'"
                                v-model="model.cancel_reason"
                                rows="4"
                                class="form-control"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </ts-loading-banner>
        </a-modal>
        <a-modal
            v-if="show_form"
            :title="$t('pickupRequest.pageTitle')"
            width="400px"
            v-model="show_form"
            :centered="true"
            :footer="null"
            :zIndex="1020"
            @close="onClose"
        >
            <FormRequestPickup @close="onClose" />
        </a-modal>
        <a-modal
            :title="$t('pickupRequest.nearByDriver')"
            width="90%"
            v-model="show_booking_request_assign_driver"
            :centered="true"
            :footer="null"
            :zIndex="1020"
            @cancel="onCloseShowBookingRequestDriver"
        >
            <FormBookingRequestAssignDriver
                v-model="model"
                @cancel="onCloseShowBookingRequestDriver"
                ref="request_assign_driver"
                :record="record"
            />
        </a-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";
import { Errors } from "form-backend-validation";
import FormRequestPickup from "./components/form-request-pickup.vue";
import FormBookingRequestAssignDriver from "./components/form_booking_request_assign_driver.vue";

export default {
    name: "pickupRequestIndex",
    components: {
        FormRequestPickup,
        FormBookingRequestAssignDriver
    },
    data() {
        return {
            row_edit: undefined,
            row_edit_model: {
                pickup_request_id: undefined,
                service_type_id: ""
            },
            warn_request_confirm: false,
            loading: false,
            errors: new Errors(),
            show_assign_driver: false,
            show_cancel_request: false,
            cancel_request_loading: false,
            show_booking_request_assign_driver: false,
            fetch_driver: false,
            show_form: false,
            cancelReason: [],
            agencies: [],
            cancel_reason: "",
            model: {
                driver_id: null,
                shop_id: null,
                pickup_request_id: null,
                driver_status_id: 1,
                cancel_reason: ""
            },
            record: []
        };
    },
    computed: {
        ...mapState("agency/requestPickup", [
            "resources",
            "pagination",
            "formModels"
        ]),
        search: {
            get() {
                return this.$store.state.agency.requestPickup.search;
            },
            set(newValue) {
                this.$store.commit("agency/requestPickup/SET_SEARCH", newValue);
                this.$store.commit("agency/requestPickup/RESET_CURRENT_PAGE");
            }
        },
        status: {
            get() {
                return this.$store.state.agency.requestPickup.status;
            },
            set(newValue) {
                this.$store.commit("agency/requestPickup/SET_STATUS", newValue);
            }
        },
        types: {
            get() {
                return this.$store.state.agency.requestPickup.types;
            },
            set(newValue) {
                this.$store.commit("agency/requestPickup/SET_TYPES", newValue);
            }
        },
        drivers() {
            if (this.formModels.driver == undefined) return [];
            return this.formModels.driver.map(el => ({
                value: el.driver_id,
                label: el.driver_name
            }));
        },
        agency_id: {
            get() {
                return this.$store.state.agency.requestPickup.agency_id;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/requestPickup/SET_SERVICE_AGENCY",
                    newValue
                );
            }
        },
        dateRequest: {
            get() {
                return this.$store.state.agency.requestPickup.requestDate;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/requestPickup/SET_REQUEST_DATE",
                    newValue
                );
            }
        },
        columns() {
            return [
                { name: this.$t("pickupRequest.requestDate"), sortKey: 'request_time' },
                { name: this.$t("pickupRequest.shopName"), sortKey: 'shop_name' },
                { name: this.$t("pickupRequest.shopPhoneNumber"), sortKey: 'phone_number' },
                { name: this.$t("pickupRequest.destination"), sortKey: 'destination' },
                { name: this.$t("pickupRequest.packageType"), sortKey: 'package_type_kh' },
                { name: this.$t("pickupRequest.serviceType"), sortKey: 'service_type_name_kh' },
                { name: this.$t("pickupRequest.driverName"), sortKey: 'driver_name' },
                { name: this.$t("pickupRequest.driverPhone") },
                { name: this.$t("pickupRequest.assignDriverName"), sortKey: 'assign_driver' },
                {
                    name: this.$t("pickupRequest.booking"),
                    style: "text-align:center"
                },
                { name: this.$t("pickupRequest.pickupStatus") },
                { name: this.$t("pickupRequest.cancelReason") },
                { name: this.$t("pickupRequest.createdBy"), sortKey: 'created_by_name' },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 1px;"
                }
            ];
        }
    },
    methods: {
        addNew() {
            this.show_form = true;
        },
        onClose() {
            this.show_form = false;
            this.fetchData();
        },
        onCloseShowBookingRequestDriver() {
            this.show_booking_request_assign_driver = false;
            this.$refs.request_assign_driver.current_active = 0;
            this.clearInputData();
            this.fetchData();
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("agency/requestPickup/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                    this.fetchAgency();
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        createBooking(record) {
            this.$router.push({
                name: "booking-pickup-create",
                query: {
                    pickup_request_id: record.pickup_request_id
                }
            });
        },
        showConfirmRequest(record) {
            this.warn_request_confirm = true;
            this.$store
                .dispatch("agency/requestPickup/confirmRequest", {
                    pickup_request_id: record.pickup_request_id,
                    driver_id: record.alert_to_driver
                })
                .then(resp => {
                    this.$notify({ type: "success", text: resp.message });
                    this.warn_request_confirm = false;
                    this.createBooking(record);
                })
                .catch(error => {
                    this.warn_request_confirm = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        showFormAssignDriver(record) {
            this.show_assign_driver = true;
            this.model.pickup_request_id = record.pickup_request_id;
            this.model.driver_id = record.driver_id;
            this.fetchDriver();
        },
        showFormAssignBookingRequestDriver(record) {
            this.record = record;
            this.show_booking_request_assign_driver = true;
            this.model.shop_id = record.shop_id;
            this.model.pickup_request_id = record.pickup_request_id;
            this.model.vehicle_type_id = record.vehicle_type_id;
        },
        showCancelRequest(record) {
            this.cancel_request_loading = true;
            this.show_cancel_request = true;
            this.model.pickup_request_id = record.pickup_request_id;
            this.model.cancel_reason = "";
            this.cancel_reason = "";
            this.$store
                .dispatch("agency/requestPickup/getFormViewData", {
                    params: {
                        fnName: "cancelReason"
                    }
                })
                .then(resp => {
                    this.cancelReason = resp.data.cancelReason.map(el => ({
                        value: el.reason,
                        label: el.reason
                    }));
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.cancel_request_loading = false;
                });
        },
        assignDriver() {
            this.fetch_driver = true;
            this.$store
                .dispatch("agency/requestPickup/assignDriver", this.model)
                .then(resp => {
                    this.$notify({ type: "success", text: resp.message });
                    this.show_assign_driver = false;
                    this.fetchData();
                })
                .catch(error => {
                    this.fetch_driver = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        cancelRequest() {
            this.cancel_request_loading = true;
            if (this.cancel_reason != "ផ្សេងៗ") {
                this.model.cancel_reason = this.cancel_reason;
            }
            this.$store
                .dispatch("agency/requestPickup/cancelRequest", this.model)
                .then(resp => {
                    this.$notify({ type: "success", text: resp.message });
                    this.model.cancel_reason = "";
                    this.cancel_reason = "";
                    this.show_cancel_request = false;
                    this.fetchData();
                })
                .catch(error => {
                    this.fetch_driver = false;
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.cancel_request_loading = false;
                });
        },
        clearInputData() {
            this.model.driver_id = null;
            this.model.pickup_request_id = null;
        },
        fetchDriver() {
            this.fetch_driver = true;
            this.$store
                .dispatch("agency/requestPickup/getFormViewData", {
                    params: {
                        fnName: "driver"
                    }
                })
                .finally(() => {
                    this.fetch_driver = false;
                });
        },
        fetchAgency() {
            this.$store
                .dispatch("agency/requestPickup/getFormViewData", {
                    params: {
                        fnName: "agency"
                    }
                })
                .then(response => {
                    this.agencies = response.data.agency.map(el => ({
                        value: el.agency_id,
                        label: el.agency_name
                    }));
                })
                .catch(error => {
                    console.log(error);
                });
        },
        onRowClickEditServiceType(row, index) {
            this.row_edit_model.service_type_id = row.service_type_id;
            this.row_edit_model.pickup_request_id = row.pickup_request_id;
            this.row_edit = index;
        },
        onSbmitChange() {
            this.warn_request_confirm = true;
            this.$store
                .dispatch(
                    "agency/requestPickup/changeServiceType",
                    this.row_edit_model
                )
                .then(resp => {
                    this.$notify({ type: "success", text: resp.message });
                    this.fetchData();
                    this.row_edit_model.pickup_request_id = undefined;
                    this.row_edit_model.service_type_id = "";
                    this.row_edit = undefined;
                })
                .catch(error => {
                    this.fetch_driver = false;
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.warn_request_confirm = false;
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("agency/requestPickup/RESET_STATE");
        next();
    }
};
</script>
