<template>
    <div>
        <ts-loading-banner :loading="loading">
            <div class="row">
                <div class="col-md-9" style="height: 75vh;">
                    <gmap-map
                        :center="center"
                        :zoom="zoom"
                        style="width: 100%; height: 100%"
                        ref="gmap"
                    >
                        <gmap-info-window
                            v-for="(win, index) in markers"
                            :key="index + Math.random()"
                            :options="{
                                maxWidth: 400,
                                pixelOffset: { width: 0, height: -35 }
                            }"
                            :position="{
                                lat: parseFloat(win.lat),
                                lng: parseFloat(win.lng)
                            }"
                            :opened="true"
                        >
                            <div>
                                <b>{{ win.driver_name }}</b>
                                <p
                                    class="tw-m-0 tw-text-white tw-px-1 tw-rounded-md"
                                    :class="{
                                        'tw-bg-red-500':
                                            win.driver_status === 'Busy',
                                        'tw-bg-green-500':
                                            win.driver_status === 'Free'
                                    }"
                                    style="width: fit-content;"
                                >
                                    <small>{{ win.driver_status }}</small>
                                </p>
                            </div>
                        </gmap-info-window>

                        <gmap-marker
                            v-for="(s, index) in markers"
                            :key="index"
                            :position="{
                                lat: parseFloat(s.lat),
                                lng: parseFloat(s.lng)
                            }"
                            :icon="{
                                url: icon,
                                scaledSize: { width: 35, height: 35 },
                                labelOrigin: {
                                    x: 30,
                                    y: -2
                                }
                            }"
                        />
                        <gmap-marker
                            v-if="record"
                            title="Shop"
                            :position="{
                                lat: parseFloat(record.lat),
                                lng: parseFloat(record.lng)
                            }"
                            :icon="{
                                url: shopImage
                            }"
                        />
                    </gmap-map>
                </div>
                <div class="col-md-3">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <div class="tw-flex tw-space-x-3">
                            <div
                                v-if="model.driver_id"
                                class="tw-flex tw-space-x-3"
                            >
                                <a-button
                                    type="primary"
                                    @click.prevent="request"
                                >
                                    {{ $t("requestForDelivery.request") }}
                                    <i class="fas fa-bullhorn tw-ml-3"></i>
                                </a-button>
                            </div>
                            <a-button
                                type="primary"
                                @click.prevent="getNearBy"
                                v-if="!model.driver_id"
                            >
                                <i class="fas fa-search-location tw-mr-3"></i>
                                {{ $t("requestForDelivery.nearByDriver") }}
                            </a-button>
                            <div class="row tw-w-44">
                                <div class="col-md-12">
                                    <a-select
                                        show-search
                                        v-model="model.driver_status_id"
                                        style="width: 100%"
                                        :default-active-first-option="false"
                                        :show-arrow="false"
                                        :filter-option="false"
                                        :not-found-content="null"
                                        :placeholder="
                                            $t('pickupRequest.driverStatus')
                                        "
                                        :options="driverStatuses"
                                    ></a-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="tw-mt-2 tw-border tw-rounded-md tw-p-2 tw-flex tw-justify-between tw-cursor-pointer"
                        v-if="record"
                        @click.prevent="
                            clickPaneTo({
                                lat: parseFloat(record.lat),
                                lng: parseFloat(record.lng)
                            })
                        "
                    >
                        <div>
                            <p class="tw-m-0">
                                {{ $t("pickupRequest.shopName") }}:
                                <b class="tw-to-blue-500">{{
                                    record.shop_name
                                }}</b>
                            </p>
                            <p class="tw-m-0">
                                {{ $t("pickupRequest.serviceType") }}:
                                {{ record.service_type_name_kh }}
                            </p>
                        </div>
                        <div>
                            <p class="tw-m-0">
                                {{ $t("pickupRequest.packageType") }}:
                                {{ record.package_type_kh }}
                            </p>
                            <p class="tw-m-0">
                                {{ $t("pickupRequest.vehicleType") }}:
                                {{ record.vehicle_types }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="tw-space-y-2 tw-mt-3 tw-overflow-y-scroll"
                        style="height: 65vh;"
                    >
                        <div
                            class="tw-w-full tw-p-2 tw-border tw-rounded tw-cursor-pointer"
                            v-for="(d, index) in drivers"
                            :key="index"
                        >
                            <div class="tw-flex tw-justify-between">
                                <div
                                    class="tw-flex tw-space-x-2 tw-items-center"
                                    @click.prevent="clickPaneTo(d)"
                                >
                                    <div>
                                        <i class="fas fa-user fa-2x"></i>
                                    </div>
                                    <div>
                                        <p
                                            class="tw-m-0 tw-text-lg tw-capitalize tw-text-blue-500"
                                        >
                                            {{ d.driver_name }}
                                            <span
                                                class="tw-text-white tw-px-2 tw-rounded tw-text-xs"
                                                :class="{
                                                    'tw-bg-green-500':
                                                        d.driver_status ==
                                                        'Free',
                                                    'tw-bg-red-500':
                                                        d.driver_status ==
                                                        'Busy'
                                                }"
                                                >{{ d.driver_status }}</span
                                            >
                                        </p>
                                        <p class="tw-m-0 tw-text-sm">
                                            {{ d.driver_code }}
                                        </p>
                                    </div>
                                </div>
                                <div class="tw-text-right">
                                    <ts-radio
                                        v-model="model.driver_id"
                                        :value="d.driver_id"
                                        style="padding-left: 8em; margin-right: -5px;"
                                    ></ts-radio>
                                    <p class="tw-m-0">
                                        {{
                                            differenceAsMinute(
                                                d.geo_modified_date
                                            )
                                        }}
                                    </p>
                                    <p class="tw-m-0">
                                        {{
                                            round(d.distance / 1000, 2) +
                                                " " +
                                                $t("requestForDelivery.km")
                                        }}
                                    </p>
                                </div>
                            </div>
                            <p class="tw-m-0">
                                {{ d.phone1 + " / " + d.phone2 }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </ts-loading-banner>
    </div>
</template>

<script>
import { round } from "lodash";
import { mapActions, mapState } from "vuex";
import icon from "@/assets/people-location.svg";
import moment from "moment";
import TsRadio from "../../../../components/common/TsRadio.vue";
export default {
    components: { TsRadio },
    props: ["value", "record"],
    name: "requestForDelivery",
    data() {
        return {
            icon,
            loading: false,
            current_active: 0,
            drivers: [],
            driverStatuses: [],
            model: {},
            zoom: 8,
            center: { lng: 105.57359185298843, lat: 12.291513194851614 },
            shopImage: require("@/assets/shop_image.png")
        };
    },
    created() {
        this.model = this.value;
        this.getNearBy();
        this.fetchViewData();
    },
    computed: {
        ...mapState("agency/requestPickup", ["formModels"]),
        vehicleTypeList() {
            if (!this.formModels.vehicleType) return [];

            return this.formModels.vehicleType.map(el => ({
                value: el.vehicle_type_id,
                label: el.vehicle_types
            }));
        },
        markers() {
            return this.drivers.filter(
                u => (u.lat !== null) & (u.lng !== null)
            );
        },
        differenceAsMinute: () => dateString => {
            var now = moment();
            const duration = moment.duration(now.diff(moment(dateString)));
            return duration.humanize();
        }
    },
    methods: {
        ...mapActions("agency/requestPickup", [
            "getFormViewData",
            "nearByDrive",
            "requestDelivery"
        ]),
        round,
        async fetchViewData() {
            this.loading = true;
            await this.getFormViewData({
                param: {
                    fnName: "driverStatus"
                },
                index: "driverStatus"
            }).then(resp => {
                this.driverStatuses = resp.data.driverStatus.map(el => ({
                    value: el.driver_status_id,
                    label: el.driver_status
                }));
            });
            this.loading = false;
        },
        selectDriver(driver_id) {
            this.current_active = driver_id;
            this.model.driver_id = driver_id;
        },
        getNearBy() {
            this.loading = true;
            this.nearByDrive({
                shop_id: this.model.shop_id,
                vehicle_type_id: this.model.vehicle_type_id,
                driver_status_id: this.model.driver_status_id
            })
                .then(response => {
                    this.drivers = response.data;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        request() {
            this.loading = true;
            this.requestDelivery(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.$emit("cancel");
                    this.loading = false;
                });
        },
        clickPaneTo(record) {
            if ((record.lat !== null) & (record.lng !== null)) {
                this.$refs.gmap.$mapPromise.then(map => {
                    map.panTo({
                        lat: parseFloat(record.lat),
                        lng: parseFloat(record.lng)
                    });
                    this.zoom = 20;
                });
            }
        }
    }
};
</script>
<style lang="css" scoped>
.active {
    background: rgba(209, 213, 219);
}
</style>

<style>
.gm-ui-hover-effect {
    opacity: 0.6;
    display: none !important;
}
</style>
